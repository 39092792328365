import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";
import { listOfCategory } from "../../../constants";
import { usePathLang } from "../../../hooks/usePathLang";
import { langRedirect } from "../../../helpers/redirect";

const Lists = () => {
  const { t } = useTranslation();
  const { detectLang } = usePathLang();

  const redirectWithLang = (link: string) => langRedirect(detectLang, link)


  const renderLists = () => {
    return listOfCategory.map((val) => {
      const productName = t(val.name);

      return (
        <div className="col-lg-4 col-md-6 pb-1" key={val.slug}>
          <Link
            to={redirectWithLang(`/product/${val.slug}`)}
            className="cat-item d-flex flex-column border mb-4"
            style={{ padding: "30px" }}
          >
            <div
              className="cat-img position-relative overflow-hidden mb-3"
            >
              <img
                className="img-fluid Sirv"
                src={val.src}
                alt={val.name}
              />
            </div>
            <h5 className="font-weight-semi-bold m-0">{productName}</h5>
          </Link>
        </div>
      );
    });
  };

  return (
    <ComponentWow>
      <div className="container-fluid pt-5">
        <div className="row px-xl-5 pb-3">{renderLists()}</div>
      </div>
    </ComponentWow>
  );
};

export default Lists;
